import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3e8867ae"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "w-100" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_el_checkbox_group = _resolveComponent("el-checkbox-group")!
  const _component_el_option = _resolveComponent("el-option")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.mode === 'DESIGN')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (!_ctx.expanding)
            ? (_openBlock(), _createBlock(_component_el_select, {
                key: 0,
                modelValue: _ctx._value,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx._value) = $event)),
                class: "max-fill",
                size: "default",
                multiple: "",
                disabled: "",
                placeholder: _ctx.placeholder
              }, null, 8, ["modelValue", "placeholder"]))
            : (_openBlock(), _createBlock(_component_el_checkbox_group, {
                key: 1,
                modelValue: _ctx._value,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx._value) = $event))
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (op, index) => {
                    return (_openBlock(), _createBlock(_component_el_checkbox, {
                      key: index,
                      disabled: "",
                      label: op.value
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(op.name), 1)
                      ]),
                      _: 2
                    }, 1032, ["label"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["modelValue"]))
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (!_ctx.readerMode)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                (!_ctx.expanding)
                  ? (_openBlock(), _createBlock(_component_el_select, {
                      key: 0,
                      modelValue: _ctx._value,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx._value) = $event)),
                      class: "max-fill",
                      multiple: "",
                      size: "default",
                      clearable: "",
                      placeholder: _ctx.placeholder
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (op, index) => {
                          return (_openBlock(), _createBlock(_component_el_option, {
                            key: index,
                            value: op.value,
                            label: op.name
                          }, null, 8, ["value", "label"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["modelValue", "placeholder"]))
                  : (_openBlock(), _createBlock(_component_el_checkbox_group, {
                      key: 1,
                      modelValue: _ctx._value,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx._value) = $event))
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (op, index) => {
                          return (_openBlock(), _createBlock(_component_el_checkbox, {
                            key: index,
                            label: op.value
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(op.name), 1)
                            ]),
                            _: 2
                          }, 1032, ["label"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["modelValue"]))
              ], 64))
            : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.readerValue), 1))
        ]))
  ]))
}